$fonts-src: './assets/fonts';

@font-face {
    font-family: 'Mark Pro';
    src: url('#{$fonts-src}/markpro/MarkPro-Bold.eot');
    src: url('#{$fonts-src}/markpro/MarkPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-src}/markpro/MarkPro-Bold.woff2') format('woff2'),
    url('#{$fonts-src}/markpro/MarkPro-Bold.woff') format('woff'),
    url('#{$fonts-src}/markpro/MarkPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNextLTW05';
    src: url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Medium.eot');
    src: url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Medium.woff2') format('woff2'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Medium.woff') format('woff'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'DINNextLTW05Bold';
    src: url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Bold.eot');
    src: url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Bold.woff2') format('woff2'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Bold.woff') format('woff'),
    url('#{$fonts-src}/dinnextltw05/DINNextLTW05-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tungsten-Bold';
    src: url('#{$fonts-src}/tungsten/Tungsten-Bold.eot');
    src: url('#{$fonts-src}/tungsten/Tungsten-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-src}/tungsten/Tungsten-Bold.woff2') format('woff2'),
    url('#{$fonts-src}/tungsten/Tungsten-Bold.woff') format('woff'),
    url('#{$fonts-src}/tungsten/Tungsten-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next W1G';
    src: url('#{$fonts-src}/dinnextw1g/DINNextW1G.eot');
    src: url('#{$fonts-src}/dinnextw1g/DINNextW1G.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts-src}/dinnextw1g/DINNextW1G.woff2') format('woff2'),
    url('#{$fonts-src}/dinnextw1g/DINNextW1G.woff') format('woff'),
    url('#{$fonts-src}/dinnextw1g/DINNextW1G.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarkSCOffcW05';
    src: url('#{$fonts-src}/ffmark/MarkSCOffcW05-Regular.eot');
    src: url('#{$fonts-src}/ffmark/MarkSCOffcW05-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-src}/ffmark/MarkSCOffcW05-Regular.woff2') format('woff2'),
        url('#{$fonts-src}/ffmark/MarkSCOffcW05-Regular.woff') format('woff'),
        url('#{$fonts-src}/ffmark/MarkSCOffcW05-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Valorant Font';
  src: url('#{$fonts-src}/valorant/Valorant Font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
    background-color: #ece8e1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Disable the native scrollbar */
body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  
  /* Styles pour une scrollbar invisible */
/* Pour Chrome, Edge et Safari */
::-webkit-scrollbar {
    width: 0em; /* Largeur de la scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fond transparent de la scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* Couleur transparente pour la poignée de la scrollbar */
  }
  
  /* Pour Firefox */
  /* Veuillez noter que Firefox ne prend pas en charge la personnalisation de la scrollbar */
  /* Vous pouvez essayer d'utiliser les propriétés scrollbar-color et scrollbar-width */
  /* Cependant, cela peut ne pas fonctionner dans toutes les versions de Firefox */
  
/* Dans votre fichier CSS ou directement dans le composant */
.event-date {
    margin-left: -12px; /* Vous pouvez ajuster la valeur selon vos besoins */
  }
  

  .rounded-image {
    border-radius: 10px; /* Changer la valeur pour ajuster le degré d'arrondi */
  }
  
  

  
  .menu-link {
    margin-bottom: 20px; /* Ajustez la valeur selon vos préférences */
  }
  

  .selected-link {
    color: white; /* Couleur bleue pour le lien sélectionné */
    font-weight: bold; /* Texte en gras pour le lien sélectionné */
  }
  
  
  /* Socials.css */
.arrow-icon {
  transition: transform 0.2s ease-out;
}

.arrow-icon:hover {
  transform: translate(0, -5px) rotate(-90deg);
}


/* Dans votre feuille de style CSS ou via une balise <style> */
.lien-inscriptions {
  color: #2CB9FF; /* Couleur bleue */
  text-decoration: underline;
  /* Autres styles si nécessaires */
}

/* Dans votre feuille de style CSS ou via une balise <style> */
.souligne {
  text-decoration: underline; /* Soulignement du texte */
}


/* Dans votre feuille de style CSS ou via une balise <style> */
.gras {
  color: #000000; /* Couleur bleue */
  text-decoration: underline;
  /* Autres styles si nécessaires */
}

/* Dans votre feuille de style CSS ou via une balise <style> */
.souligne {
  text-decoration: underline; /* Soulignement du texte */
}

.mr-custom {
  margin-right: 1px; /* Vous pouvez ajuster la valeur selon vos préférences */
}
